<template>
  <div class="main-wrap">
    <div class="bread-wrap">
      <span class="bread-title"
        >{{ safeform.authStatus ? "企业中心" : "个人中心" }}/基本信息</span
      >
    </div>
    <div class="content">
      <div class="main-left">
        <p class="title-wrap">
          <i class="iconfont icon-267 titleicon"></i
          ><span class="title">账户基本信息</span>
        </p>
        
        <div class="accounttext">
          <span class="accountlabel">手机号码：</span>
          <span class="accountcontent">{{
            basicform.accountPhoneNumber
              ? basicform.accountPhoneNumber.substring(0, 4) +
                "****" +
                basicform.accountPhoneNumber.substring(7)
              : ""
          }}</span>
          <el-button @click="editAccountPhone" class="changebtn" type="text">
            <i class="iconfont icon-shuruxiugai"></i>修改
          </el-button>
        </div>

        <div class="accounttext">
          <span class="accountlabel">账 号 名：</span>
          <span class="accountcontent">{{
            basicform.username == basicform.userid
              ? "暂未设置"
              : basicform.username
          }}</span>
          <el-button
            v-if="
              !this.basicform.subaccount &&
              basicform.username == basicform.userid
            "
            @click="setUsername"
            class="recharge-button"
            size="small"
            type="primary"
            >设置</el-button
          >
        </div>
        <div class="accounttext">
          <span class="accountlabel">账号类型：</span>
          <span class="accountcontent">{{ accountType }}</span>
        </div>
        <div class="accounttext" v-if="!this.basicform.subaccount">
          <span class="accountlabel">账户余额：</span>
          <span class="accountcontent">{{ balance.toFixed(2) }} 元</span>
          <el-button
            @click="recharge"
            class="recharge-button"
            size="small"
            type="primary"
            >充值</el-button
          >
        </div>
        <div class="accounttext">
          <span class="accountlabel">用户昵称：</span>
          <span class="accountcontent">{{ basicform.nickname }}</span>
          <el-button @click="editName" class="changebtn" type="text">
            <i class="iconfont icon-shuruxiugai"></i>修改
          </el-button>
        </div>
        <div class="accounttext">
          <span class="accountlabel">用户性别：</span>
          <span class="accountcontent">{{ basicform.gender }}</span>
          <el-button @click="editGender" class="changebtn" type="text">
            <i class="iconfont icon-shuruxiugai"></i>修改
          </el-button>
        </div>
        <div class="accounttext">
          <span class="accountlabel">接收通知手机：</span>
          <span class="accountcontent">{{
            basicform.notifyPhoneNumber
              ? basicform.notifyPhoneNumber.substring(0, 4) +
                "****" +
                basicform.notifyPhoneNumber.substring(7)
              : ""
          }}</span>
          <el-button @click="editNofityPhone" class="changebtn" type="text">
            <i class="iconfont icon-shuruxiugai"></i>修改
          </el-button>
        </div>
      </div>
      <div class="content-right">
        <div class="aqxx">
          <p class="title-wrap">
            <i class="iconfont icon-yunanquan titleicon"></i>
            <span class="title">账户安全信息</span>
          </p>
          <p class="title-wrap" v-if="!this.basicform.subaccount">
            <i class="iconfont icon-bind-phone titleicon"></i
            ><span class="safetitle">密码</span>
          </p>
          <div class="ff-detail" v-if="!this.basicform.subaccount">
            <span class="dtcontent">定期修改密码可提高安全性</span>
            <div class="ff-detail">
              <el-button
                @click="changePassword"
                class="modbtn"
                size="small"
                type="primary"
                >修改</el-button
              >
            </div>
          </div>
          <!-- -->
          <p class="title-wrap" v-if="!this.basicform.subaccount">
            <i class="iconfont icon-weixin titleicon"></i
            ><span class="safetitle">绑定微信</span>
          </p>
          <div class="ff-detail" v-if="!this.basicform.subaccount">
            <span class="dtcontent">{{ wechatText }}</span>
            <div class="ff-detail">
              <el-button
                @click="bindWeChat"
                class="modbtn"
                size="small"
                type="primary"
                >{{ wechatBindTitle }}</el-button
              >
            </div>
          </div>
          <p class="title-wrap" v-if="!this.basicform.subaccount">
            <i class="iconfont icon-real-auth titleicon"></i
            ><span class="safetitle">实名认证</span>
          </p>
          <div class="ff-detail" v-if="!this.basicform.subaccount">
            <span
              :class="[{ certoktext: safeform.authStatus == true }, 'certtext']"
              >{{ authText }}</span
            >
            <div class="ff-detail">
              <el-button
                v-if="!safeform.authStatus"
                @click="realNameCert"
                class="modbtn"
                size="small"
                type="primary"
                >去认证</el-button
              >
            </div>
          </div>
          <div class="invite-line">
            <div class="invite-text">我的邀请注册链接</div>
            <el-button
              @click="copyInviteLink"
              class="invite-button"
              size="small"
              type="primary"
              >点击复制</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <edit-name
      title="修改昵称"
      :name="basicform.nickname"
      :showeditename="showeditename"
      @closeeditename="closeEditName"
    />
    <edit-gender
      title="修改性别"
      :gender="basicform.gender"
      :showeditegroup="showeditgender"
      @closeeditegroup="closeEditGender"
    />
    <edit-notify-phone
      title="修改接收通知手机"
      :phone="basicform.notifyPhoneNumber"
      :showeditnotifyphone="showeditnotifyphone"
      @closeeditnotifyphone="closeEditNotifyPhone"
    />
    <edit-account-phone
      title="修改手机号码"
      :phone="basicform.accountPhoneNumber"
      :showeditaccountphone="showeditaccountphone"
      @closeeditaccountphone="closeEditAccountPhone"
    />
    <edit-username
      title="修改账户名"
      :showeditusername="showeditusername"
      @closeeditusername="closeEditUserame"
    />
  </div>
</template>
<script>
import { replaceStr } from "@/utils/validate";
import EditAccountPhone from "./Models/EditAccountPhone";
import EditName from "./Models/EditName";
import EditUsername from "./Models/EditUsername";
import EditGender from "./Models/EditGender";
import EditNotifyPhone from "./Models/EditNotifyPhone";
import navigation from "@/utils/navigation";

const clipboard = require("clipboardy");

export default {
  name: "personalcenter",
  data() {
    return {
      basicform: {
        userid: "未知",
        accountPhoneNumber:"",
        nickname: "暂无",
        password: "",
        gender: "",
        apikey: "",
        subaccount: false,
        username: "",
        notifyPhoneNumber: "",
      },
      safeform: {
        mailbox: "xxx@wumitech.com",
        phonenum: "xxxxxxxxxxx",
        wechatstatus: false,
        authStatus: false,
      },
      wechatText: "未绑定",
      wechatBindTitle: "绑定",
      authText: "未完成实名认证",
      showeditename: false,
      showeditusername: false,
      showeditgender: false,
      showeditnotifyphone: false,
      showeditaccountphone: false,
      accountType: "主账号",
      balance: 0,
      invitationLink: "",
    };
  },
  components: { EditAccountPhone, EditName, EditGender, EditNotifyPhone, EditUsername },
  methods: {
    getUserInfo() {
      this.$store.dispatch("user/getuserinfo").then((res) => {
        if (res.RetCode == 0) {
          this.basicform.userid = res.UserId;
          this.basicform.username = res.Username;
          this.basicform.nickname = res.NickName;
          this.basicform.gender = res.Gender;
          this.basicform.subaccount = res.SubAccount;
          this.basicform.notifyPhoneNumber = res.NotifyPhoneNumber;
          this.basicform.accountPhoneNumber = res.PhoneNumber;
          this.safeform.wechatstatus = res.Wechat;
          this.safeform.authStatus = res.Authenticated;
          this.initControls();

          this.invitationLink =
            (process.env.NODE_ENV == "preview"
              ? "https://console-preview.wumitech.com"
              : "https://console.wumitech.com") +
            "/#/register?InvitationCode=" +
            encodeURIComponent(window.btoa(res.UserId));
        }
      });
    },
    initControls() {
      if (this.safeform.wechatstatus) {
        this.wechatText = "已绑定";
        this.wechatBindTitle = "解绑";
      } else {
        this.wechatText = "未绑定";
        this.wechatBindTitle = "绑定";
      }
      if (this.safeform.authStatus) {
        this.authText = "已完成实名认证";
      } else {
        this.authText = "未完成实名认证";
      }
      if (this.basicform.subaccount) {
        this.accountType = "子账号";
      } else {
        this.accountType = "主账号";
      }
    },
    editName() {
      this.showeditename = !this.showeditename;
    },
    closeEditName() {
      this.showeditename = !this.showeditename;
      this.getUserInfo();
    },
    setUsername() {
      this.showeditusername = !this.showeditusername;
    },
    closeEditUserame() {
      this.showeditusername = !this.showeditusername;
      this.getUserInfo();
    },
    editGender() {
      this.showeditgender = !this.showeditgender;
    },
    closeEditGender() {
      this.showeditgender = !this.showeditgender;
      this.getUserInfo();
    },
    editAccountPhone() {
      this.showeditaccountphone = !this.showeditaccountphone;
    },
    closeEditAccountPhone() {
      this.showeditaccountphone = !this.showeditaccountphone;
      this.getUserInfo();
    },
    editNofityPhone() {
      this.showeditnotifyphone = !this.showeditnotifyphone;
    },
    closeEditNotifyPhone() {
      this.showeditnotifyphone = !this.showeditnotifyphone;
      this.getUserInfo();
    },
    changePassword() {
      this.$router.push("/user/resetnewpass");
    },
    checkApiKey() {},
    bindMailBox() {},
    bindWeChat() {
      if (this.safeform.wechatstatus) {
        //已绑定，去解绑
        this.$store
          .dispatch("user/unbindthirdparty", { ThirdParty: "wechat" })
          .then((res) => {
            console.log("unbindthirdparty response: " + JSON.stringify(res));
            if (res.RetCode == 0) {
              this.$message.success("解绑成功!");
              this.getUserInfo();
            } else {
              //获取失败，前端计算保底
              this.$message.error("解绑失败," + res.Message);
            }
          });
      } else {
        window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=wx70dd623f59efd16f&redirect_uri=${encodeURIComponent(
          "https://console.wumitech.com/#/user/personalcenter"
        )}&response_type=code&scope=snsapi_login&state=${Math.ceil(
          Math.random() * 1000
        )}`;
      }
    },
    realNameCert() {
      this.$router.push("/user/enterprise");
    },
    getUserBalance() {
      this.$store.dispatch("user/getuserwallet").then((res) => {
        if (res.RetCode == 0) {
          this.balance = res.Balance / 100;
        }
      });
    },
    recharge() {
      navigation.navigateTo({ url: "recharge" });
    },
    copyInviteLink() {
      clipboard.write(this.invitationLink).then(() => {
        this.$message.success("复制链接成功");
      });
    },
  },
  mounted() {
    this.getUserInfo();
    this.getUserBalance();
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>