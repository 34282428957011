<template>
  <models
    :showmodel="showeditnotifyphone"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @confirm="confirmedit"
    @closemodel="closeeditnotifyphone"
    class="ifeditename"
  >
    <div class="maincontent">
      <el-row>
        <el-col :span="6">
          <div class="labelname"><span>接收通知手机：</span></div>
        </el-col>
        <el-col :span="18">
          <el-input size="small" v-model="PhoneNumber" clearable></el-input>
        </el-col>
      </el-row>
    </div>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
export default {
  props: {
    showeditnotifyphone: {
      type: Boolean,
    },
    phone: {
      type: String,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
      default: "取消",
    },
    confirmtext: {
      type: String,
      default: "确定",
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      PhoneNumber: this.phone,
    };
  },
  watch: {
    phone: function (val) {
      this.PhoneNumber = val;
    },
  },
  components: {
    Models,
  },
  methods: {
    confirmedit() {
      let params = {
        
      }
      this.$store
      .dispatch("user/setnotifyphone", { PhoneNumber: this.PhoneNumber })
      .then((res) => {
        console.log("set user info response: " + JSON.stringify(res))
        if (res.RetCode == 0) {
          this.$message.success("设置成功");
          this.$emit("closeeditnotifyphone");
        } else {
          this.$message.error("设置失败," + res.Message);
        }
      });
    },
    closeeditnotifyphone() {
      this.$emit("closeeditnotifyphone");
    },
  },
};
</script>
<style lang="scss" scoped>
.ifeditename {
  ::v-deep .el-dialog {
    width: 450px;
    height: 250px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 80px;
  }
  ::v-deep .el-table::before {
    background-color: #fff;
  }
  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }
  .maincontent {
    margin-top: 20px;
  }
  .warningtitle {
    height: 40px;
    margin-bottom: 20px;
    background-color: rgba(228, 229, 241);
    line-height: 40px;
    color: rgb(102, 102, 102);
    font-size: 14px;
    .warningcontent {
      margin-left: 20px;
    }
  }
}
</style>