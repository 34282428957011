<template>
  <models
    :showmodel="showeditusername"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @confirm="confirmedit"
    @closemodel="closeeditusername"
    class="ifeditename"
  >
    <div class="maincontent">
      <el-row>
        <el-col :span="6">
          <div class="labelname"><span>账户名：</span></div>
        </el-col>
        <el-col :span="18">
          <el-input size="small" v-model="Name" clearable></el-input>
        </el-col>
      </el-row>
      <div class="tips">
        <p>注意事项</p>
        <p>1. 账户名为6位或以上的字母、数字或下划线</p>
        <p>2. 账户名仅可以设置一次，请谨慎操作</p>
        <p>3. 账户名设置后，子账号将已该账户名作为后缀</p>
      </div>
    </div>
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
export default {
  props: {
    showeditusername: {
      type: Boolean,
    },
    name: {
      type: String,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
      default: "取消",
    },
    confirmtext: {
      type: String,
      default: "确定",
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      Name: "",
    };
  },
  watch: {
   
  },
  components: {
    Models,
  },
  methods: {
    confirmedit() {
      if (!this.validate(this.Name)) {
        this.$message.error("请输入正确的账户名");
        return
      }
      let params = {
        Username: this.Name
      }
      this.$store
      .dispatch("user/setusername", { ...params })
      .then((res) => {
        if (res.RetCode == 0) {
          this.$message.success("设置成功");
          this.$emit("closeeditusername");
        } else {
          this.$message.error(res.Message || "设置失败,");
        }
      });
    },
    closeeditusername() {
      this.$emit("closeeditusername");
    },
    validate(path) {
      return  /^[a-zA-Z0-9_]{6,32}$/.test(path)
    }
  },
};
</script>
<style lang="scss" scoped>
.ifeditename {
  ::v-deep .el-dialog {
    width: 450px;
    // height: 250px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    // height: 80px;
    padding: 30px 20px 20px 20px;
  }
  ::v-deep .el-table::before {
    background-color: #fff;
  }
  .labelname {
    min-height: 32px;
    line-height: 32px;
    text-align: center;
  }
  .maincontent {
    // margin-top: 20px;
  }
  .warningtitle {
    height: 40px;
    margin-bottom: 20px;
    background-color: rgba(228, 229, 241);
    line-height: 40px;
    color: rgb(102, 102, 102);
    font-size: 14px;
    .warningcontent {
      margin-left: 20px;
    }
  }

  .tips {
    font-size: 13px;
    color: #999;
    margin: 16px 0 0 22px;
  }
}
</style>